<template >
  <di>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.kpTemplate"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                        ></el-input>

                      </el-form-item>
                      <el-form-item
                      prop="name"
                      :label="$t('message.header')"
                      >
                        <ckeditor :editor="editor" v-model="form.header" :config="editorConfig"></ckeditor>
                      </el-form-item>

                      <el-form-item
                      prop="name"
                      :label="$t('message.footer')"
                      >
                        <ckeditor :editor="editor" v-model="form.footer" :config="editorConfig"></ckeditor>
                      </el-form-item>

                      <el-form-item
                        :label="$t('message.categories')"
                        class="label_mini"
                        prop="categories"
                      >
                        <el-tree
                          ref="category"
                          :data="updatedCategories"
                          show-checkbox
                          node-key="id"
                          :default-checked-keys="[]"
                          :props="defaultProps">
                        </el-tree>
                      </el-form-item>

                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </di>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default {
  mixins: [form, drawer],
  components: {  },
  data() {
    return {
      editor: Editor,
      editorConfig: {
          // The configuration of the editor.
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      updatedCategories: []
    };
  },
  created() {},

  computed: {
      ...mapGetters({
          rules: "kpTemplates/rules",
          model: "kpTemplates/model",
          columns: "kpTemplates/columns",
          categories: "categories/treeInventory",
      }),
  },
  methods: {
      ...mapActions({
          save: "kpTemplates/store",
          updateInventory: "categories/treeInventory"
      }),
      onReady( editor )  {
          // Insert the toolbar before the editable area.
          editor.ui.getEditableElement().parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
          );
      },
      afterOpen() {
        this.updateInventory().then(res => {
          this.updatedCategories = JSON.parse(JSON.stringify(this.categories));
        });
      },
      submit(close = true) {
        if(this.$refs.category){
          this.form.categories = this.$refs.category.getCheckedKeys();
        }
        this.$refs["form"].validate((valid) => {
            if (valid) {
                this.loadingButton = true;
                this.save(this.form)
                    .then((res) => {
                        this.loadingButton = false;
                        this.$alert(res);
                        this.parent().listChanged();
                        if (close) this.close();
                    })
                    .catch((err) => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
            }
        });
      },
  },
};
</script>

