<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.kpTemplate"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item :label="$t('message.name')" prop="name" class="label_mini">
                            <crm-input :size="'medium'" :inputValue="form.name" v-model="form.name" :placeholder="$t('message.name')"></crm-input>
                        </el-form-item>
                        <el-form-item
                        prop="name"
                        :label="$t('message.header')"
                        >
                          <ckeditor :editor="editor" v-model="form.header" :config="editorConfig"></ckeditor>

                        </el-form-item>

                        <el-form-item
                        prop="name"
                        :label="$t('message.footer')"
                        >
                          <ckeditor :editor="editor" v-model="form.footer" :config="editorConfig"></ckeditor>

                        </el-form-item>

                        <el-form-item
                          :label="$t('message.categories')"
                          class="label_mini"
                          prop="categories"
                        >
                          <el-tree
                            ref="category"
                            :data="updatedCategories"
                            show-checkbox
                            node-key="id"
                            :default-checked-keys="form.categories"
                            :props="defaultProps">
                          </el-tree>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>

    <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
            :with-header="false"
            :visible.sync="kPPreview"
            ref="drawerCreate"
            class="body_scroll"
            size="60%"
            @opened="drawerOpened('drawerCreateChild')"
            @closed="drawerClosed('drawerCreateChild')"
            >
            <div>
                <KPpreview ref="drawerCreateChild" drawer="drawerCreate" :header="form.header" :footer="form.footer"> </KPpreview>
            </div>
        </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import KPpreview from "./KPpreview";

export default {
    mixins: [form, drawer, show],
    name: "kpTemplate",
    components: {
      KPpreview
    },

    data() {
        return {
          editor: Editor,
          editorConfig: {
              height: '100px',
              width: '100px',
          },
          kPPreview: false,
          defaultProps: {
            children: 'children',
            label: 'name'
          },
          updatedCategories: []
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "kpTemplates/rules",
            model: "kpTemplates/model",
            columns: "kpTemplates/columns",
            categories: "categories/treeInventory",
        }),
    },
    methods: {
        ...mapActions({
            update: "kpTemplates/update",
            show: "kpTemplates/show",
            updateInventory: "categories/treeInventory"
        }),
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        afterOpen() {
          this.updateInventory().then(res => {
            this.updatedCategories = JSON.parse(JSON.stringify(this.categories));
          });
          this.fetchData();
        },
        submit(close = true) {
          if(this.$refs.category){
            this.form.categories = this.$refs.category.getCheckedKeys();
          }
          this.$refs["form"].validate((valid) => {
              if (valid) {
                this.loadingButton = true;
                this.update(this.form)
                  .then((res) => {
                      this.loadingButton = false;
                      this.$alert(res);
                      this.parent().listChanged();
                      if (res.status == 200) this.close();
                  })
                  .catch((err) => {
                      this.loadingButton = false;
                      this.$alert(err);
                  });
              }
          });
        },
    },
};
</script>
<style>

</style>
